import React, { ChangeEvent, useState, useEffect, useRef, useCallback } from 'react';
import { styled } from '@material-ui/core/styles';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import Client from 'twilio-chat';

const Container = styled('aside')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: `calc(100% - ${theme.sidebarWidth}px)`,
  right: 0,
  padding: '0',
  overflowY: 'auto',
  '@media (max-width:800px)': {
    position: 'fixed',
    zIndex: 50,
    paddingTop: '70px',
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapPageChat: {
      height: 'calc(100% - 80px)',
      overflow: 'auto',
      background: '#fff',
      borderBottom: '1px solid #ddd',
      padding: 5,
      '@media (max-width:800px)': {
        height: 'calc(100% - 120px)',
      },
    },
    wrapChatAction: {
      display: 'flex',
      height: '40px',
      position: 'relative',
      padding: '5px',
    },
    inputChat: {
      width: '100%',
      border: 'none',
      color: '#fff',
      borderBottom: '1px solid #a58feb',
      background: '#fff',
      paddingRight: '40px',
    },
    sendButton: {
      width: '40px',
      position: 'absolute',
      right: 0,
      top: 0,
      height: '40px',
      border: 'none',
      padding: '12px',
      background: 'transparent',
      zIndex: 10,
    },
    sendImg: {
      width: '100%',
      filter: 'contrast(0)',
    },
    typing: {
      color: '#fff',
      background: '#2b2b2b',
      position: 'absolute',
      bottom: '40px',
      width: '295px',
      padding: '7px',
      fontSize: '10px',
    },
  })
);

export default function RoomChat(isShow: any) {
  const {
    room: { localParticipant },
    seconds,
    roomType,
    getMobileDetect,
  } = useVideoContext();
  const { user, getTokenChat, setChannelChat } = useAppState();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const URLRoomName = params.get('room');
  const userId = params.get('name');

  const [message, setMessage] = useState<string>('');
  const [channel, setChannel] = useState<any>(null);
  const [listMessage, setListMessage] = useState<any>([]);
  const [isGettingChannel, toggleGettingChanel] = useState<boolean>(false);
  const [isGettingChatMessage, toggleGettingChatMessage] = useState<boolean>(true);

  const [isTyping, setTyping] = useState<boolean>(false);
  const classes = useStyles(roomType);

  const inputElement = useRef(null) as any;

  const registerChannelListeners = (c: any) => {
    if (!c) {
      return;
    }
    setChannelChat(c);
    setChannel(c);

    c.on('messageAdded', (msg: any) => {
      updateMessage(msg);
    });
    c.on('typingStarted', (member: any) => {
      //process the member to show typing
      updateTypingIndicator(member, true);
    });

    //set  the listener for the typing ended Channel event
    c.on('typingEnded', (member: any) => {
      //process the member to stop showing typing
      updateTypingIndicator(member, false);
    });
  };

  const updateTypingIndicator = useCallback((member, type) => {
    setTyping(type);
    // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }, []);

  const updateMessage = useCallback(event => {
    setListMessage((oldArray: any) => [...oldArray, event]);
    const element = document.getElementById('wrap-chat-sec');
    if (element) element.scrollTop = element.scrollHeight;

    // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }, []);

  useEffect(() => {
    if (inputElement && inputElement.current) {
      inputElement.current.onfocus = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      };
    }
    if (userId && URLRoomName && !isGettingChannel) {
      toggleGettingChanel(true);

      getTokenChat(userId, URLRoomName).then(async (token: any) => {
        const client = await Client.create(token);

        let _channel: any = null;
        try {
          _channel = await client.getChannelByUniqueName(URLRoomName);
        } catch {
          _channel = await client.createChannel({
            uniqueName: URLRoomName,
          });
        }

        if (_channel.status !== 'joined') {
          client.on('channelJoined', () => {
            registerChannelListeners(_channel);
          });
          await _channel.join();
        } else {
          registerChannelListeners(_channel);
        }

        const _listMessages = (await _channel.getMessages()).items;
        setListMessage(_listMessages);
        toggleGettingChatMessage(false);
        const element = document.getElementById('wrap-chat-sec');
        if (element) element.scrollTop = element.offsetHeight;
      });
    }
  }, [URLRoomName, getTokenChat, isGettingChannel, userId, seconds]);

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (channel) {
      channel.typing();
    }
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    if (message && message !== '') {
      channel.sendMessage(message);
    }
    setMessage('');
  };

  const enterPressed = (event: any) => {
    var code = event.keyCode || event.which;
    if (code === 13 && message && message !== '') {
      sendMessage();
    }
  };

  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const device = getMobileDetect(userAgent, navigator);

  // if (device.isMobile()) {
  var currentElem: any;
  var targetElem: any;

  function getTargetStart(e: any) {
    targetElem = e;
  }

  function stopScroll() {
    if (currentElem && (document.querySelector('input:focus') as any)) {
      var top = (document.querySelector('input:focus') as any).offsetTop;
      document.body.scrollTop = top;
      (document.querySelector('html') as any).scrollTop = top;

      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      // Get the device pixel ratio
      var ratio = window.devicePixelRatio || 1;

      // Define the users device screen dimensions
      var screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };
      var isIpX = false;
      // iPhone X Detection
      if (iOS && screen.width === 1125 && screen.height === 2436) {
        isIpX = true;
      }

      var h = 0;
      if (isIpX) {
        h = document.body.clientHeight - document.body.clientWidth - 15;
      } else {
        h = document.body.clientHeight - document.body.clientWidth + 30;
      }
      if (device.isIosIpad()) {
        var offset = 0;
        if (document.body.clientHeight > document.body.clientWidth) {
          offset = document.body.clientHeight - document.body.clientWidth;
        } else {
          offset = document.body.clientWidth - document.body.clientHeight - 57;
        }
        // if (document.getElementById('time-remain') as any) {
        //   h = offset + 104;
        // } else {
        if (document.getElementById('time-remain') as any) {
          if (document.body.clientWidth > 770) {
            if (device.isIosIpad()) {
              h = offset + 107;
            } else {
              h = offset + 107;
            }
          } else {
            if (device.isIosIpad()) {
              h = offset + 130;
            } else {
              h = offset + 160;
            }
          }
        } else {
          if (document.body.clientWidth > 770) {
            h = offset + 100;
          } else {
            h = offset + 160;
          }
        }
        // }
      }

      (document.getElementById('wrap-chat-c') as any).children[0].style.height = 'calc(100% - ' + h + 'px)';
      if (document.getElementById('time-remain') as any) {
        // (document.getElementById('time-remain') as any).style.bottom = h + 'px';
      } else {
        (document.getElementById('wrap-chat-c') as any).children[0].style.position = 'absolute';
        (document.getElementById('wrap-chat-c') as any).children[0].style.left = '0px';
        (document.getElementById('wrap-chat-c') as any).children[0].style.margin = '0px';
      }
      const element = document.getElementById('wrap-chat-sec');
      if (element) element.scrollTop = element.scrollHeight;
    }
  }

  function disableScrolling(elem: any) {
    currentElem = elem;
    document.addEventListener('touchstart', getTargetStart);
    document.addEventListener('touchend', stopScroll);
    setTimeout(function() {
      stopScroll();
    }, 10);
  }

  function enableScrolling() {
    currentElem = undefined;

    document.removeEventListener('touchend', stopScroll);
    document.removeEventListener('touchstart', getTargetStart);
  }
  // }

  const handleFocus = (e: any) => {
    // && roomType === 'chat'
    if ((device.isMobile() || device.isIosIpad()) && !device.isAndroid()) {
      disableScrolling(e);

      // (document.getElementById('header-bar') as any).children[0].style.top = '251px';
      // (document.querySelector('.wrap-control.chat') as any).style.top = '260px';
    }
  };

  const handleBlur = (e: any) => {
    //&& roomType === 'chat'
    if ((device.isMobile() || device.isIosIpad()) && !device.isAndroid()) {
      enableScrolling();
      (document.getElementById('wrap-chat-c') as any).children[0].style.height = '';
      (document.getElementById('wrap-chat-c') as any).children[0].style.position = '';
      (document.getElementById('wrap-chat-c') as any).children[0].style.left = '';
      (document.getElementById('wrap-chat-c') as any).children[0].style.margin = '';
      if (document.getElementById('time-remain') as any) {
        (document.getElementById('time-remain') as any).style.bottom = '';
      }

      // (document.getElementById('header-bar') as any).children[0].style.top = '251px';
      // (document.querySelector('.wrap-control.chat') as any).style.top = '260px';
    }
  };

  return (
    <div id="wrap-chat-c">
      <Container
        style={{
          zIndex: roomType !== 'chat' ? 999 : 'auto',
          background: roomType !== 'chat' ? '#fff' : '#fff',
          boxShadow: '0px 0px 10px #b1b0b0',
          left: roomType === 'chat' ? 0 : '',
        }}
      >
        <h3
          style={{
            margin: 0,
            padding: '.5rem',
            borderBottom: '1px solid #ddd',
            textAlign: 'center',
            height: '40px',
          }}
        >
          Text Chat
        </h3>
        <div
          id="wrap-chat-sec"
          className={classes.wrapPageChat}
          style={{
            background: roomType !== 'chat' ? '' : '#fff',
            borderBottom: roomType !== 'chat' ? '' : '1px solid #eee',
          }}
        >
          {isGettingChatMessage ? (
            <div style={{ color: roomType !== 'chat' ? '#222' : '#a58feb' }}>
              {URLRoomName ? 'Getting chat room...' : 'No room found'}
            </div>
          ) : (
            ''
          )}
          {listMessage.map((_m: any, i: any) => (
            <p key={i} style={{ color: roomType !== 'chat' ? '#222' : '#a58feb', marginTop: 0 }}>
              <span style={{ color: _m.author === userId ? '#a58feb' : '', fontWeight: 500 }}>
                {' '}
                {_m.author === userId ? 'You' : _m.author}:{' '}
              </span>{' '}
              {_m.body}
            </p>
          ))}
          {isTyping ? <div className={classes.typing}>Someone is typing...</div> : ''}
        </div>
        <div className={classes.wrapChatAction}>
          <input
            disabled={!URLRoomName || isGettingChatMessage}
            onBlur={e => handleBlur(e)}
            onFocus={e => handleFocus(e)}
            ref={inputElement}
            style={{ color: roomType !== 'chat' ? '#222' : '#222', background: roomType !== 'chat' ? '' : '#fff' }}
            value={message}
            type="text"
            placeholder="Enter something..."
            className={classes.inputChat}
            onChange={handleMessageChange}
            onKeyPress={e => enterPressed(e)}
          />
          <button title="Send" className={classes.sendButton} onClick={sendMessage}>
            <img src="//cdn.onlinewebfonts.com/svg/img_365536.png" alt="" className={classes.sendImg} />
          </button>
        </div>
      </Container>
    </div>
  );
}

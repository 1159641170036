import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';
import ToggleChatRoom from './ToggleChatRoom/ToggleChatRoom';
import SwitchRoomButton from './SwitchRoomButton/SwitchRoomButton';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      // transform: 'translate(50%, 30px)',
      bottom: '10px',
      zIndex: 200,
      transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
      opacity: 1,
      visibility: 'visible',
      transform: 'translate(50%, 0px)',
      width: ' 100%',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      // '&.showControls': {
      //   transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
      //   opacity: 1,
      //   visibility: 'visible',
      //   transform: 'translate(50%, 0px)',
      // },
      '@media (max-width:800px)': {
        bottom: '30px',
      },
    },
    buttonClass: {},
  })
);

export default function Controls(props: any) {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = roomState === 'disconnected'; //isUserActive ||
  const { roomType, setRoomType, room } = useVideoContext();

  return (
    <div className={`wrap-control ${roomType}`}>
      <div className={clsx(classes.container)}>
        {roomType !== 'chat' && (
          <>
            <ToggleAudioButton disabled={isReconnecting} />
            <ToggleChatRoom
              disabled={isReconnecting}
              isChatRoomEnable={props.isChatRoomEnable}
              toggleChatRoomEnable={props.toggleChatRoomEnable}
            />
            <ToggleVideoButton disabled={isReconnecting} />
          </>
        )}

        {roomState !== 'disconnected' && (
          <>
            {roomType !== 'chat' ? <ToggleScreenShareButton disabled={isReconnecting} /> : ''}
            <EndCallButton />
          </>
        )}
        {roomState !== 'disconnected' && <SwitchRoomButton disabled={isReconnecting} />}
      </div>
    </div>
  );
}

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import SpeakerNotes from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOff from '@material-ui/icons/SpeakerNotesOff';

import useChatRoomToggle from '../../../hooks/useChatRoomToggle/useChatRoomToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      color: '#fff',
      '@media (max-width:800px)': {
        width: '40px',
        height: '40px',
      },
    },
  })
);

export default function ToggleChatRoom(props: {
  disabled?: boolean;
  isChatRoomEnable: any;
  toggleChatRoomEnable: any;
}) {
  const classes = useStyles();
  // style={{
  //   background: !props.isChatRoomEnable ? '#E91E63': ''
  // }}
  return (
    <Tooltip
      title={props.isChatRoomEnable ? 'Hide Chat' : 'Show Chat'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab color="primary" className={classes.fab} onClick={props.toggleChatRoomEnable} disabled={props.disabled}>
        {props.isChatRoomEnable ? <SpeakerNotes /> : <SpeakerNotesOff />}
      </Fab>
    </Tooltip>
  );
}

import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

const Container = styled('aside')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: `calc(100% - ${theme.sidebarWidth}px)`,
  left: 0,
  padding: '0.5em',
  overflowY: 'auto',
  background: '#fff',
  boxShadow: '0px 0px 10px #b1b0b0',
  '@media (max-width:800px)': {
    position: 'relative',
    height: '185px',
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
    getMobileDetect,
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const device = getMobileDetect(userAgent);
  console.log(participants, localParticipant);
  return (
    <Container>
      <Participant
        key={localParticipant.sid}
        hide={false}
        participant={localParticipant}
        isSelected={selectedParticipant === localParticipant}
        onClick={() => setSelectedParticipant(localParticipant)}
      />

      {participants.map(participant => (
        <Participant
          key={participant.sid}
          hide={participant.sid != localParticipant.sid}
          participant={participant}
          isSelected={selectedParticipant === participant}
          onClick={() => setSelectedParticipant(participant)}
        />
      ))}
    </Container>
  );
}

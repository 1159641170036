import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SpeakerNotesOff from '@material-ui/icons/SpeakerNotesOff';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      color: '#fff',
      '@media (max-width:800px)': {
        width: '40px',
        height: '40px',
      },
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { roomType, room, isShowTimeLeft } = useVideoContext();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const URLRoomName = params.get('room');
  const userId = params.get('name');

  // const { URLRoomName } = useParams();
  const { deleteRoomFromDB, channelChat } = useAppState();

  const handleEndCall = () => {
    room.disconnect();
  };
  return (
    <Tooltip
      title={roomType !== 'chat' ? 'End Call' : 'End Chat'}
      onClick={handleEndCall}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ background: '#E91E63' }}
    >
      <Fab className={classes.fab} color="primary">
        {roomType !== 'chat' ? <CallEnd /> : <SpeakerNotesOff />}
      </Fab>
    </Tooltip>
  );
}

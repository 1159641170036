import React, { useCallback, useState, useEffect } from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { styled, makeStyles, createStyles } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import RoomChat from '../RoomChat/RoomChat';

import theme from '../../theme';
import './Room.css';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';

const Container = styled('div')({
  position: 'relative',
  height: '100%',
  background: '#fff',
});

const MainParticipantContainer = styled('div')({
  position: 'absolute',
  left: theme.sidebarWidth,
  top: 0,
  bottom: 0,
  transition: '.5s all',
  '& > div': {
    height: '100%',
  },
  '@media (max-width:800px)': {
    position: 'relative',
    right: '0!important',
    left: 0,
    height: 'calc(100% - 185px)',
  },
});

export default function Room(props: any) {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    roomType,
  } = useVideoContext();
  let videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;
  let audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const [isFirstInitVideo, setIsFirstInitVideo] = useState<boolean>(true);
  const [isFirstInitAudio, setIsFirstInitAudio] = useState<boolean>(true);

  useEffect(() => {
    if (roomType !== 'chat') {
      if (audioTrack) {
      } else {
        if (isFirstInitAudio) {
          setIsFirstInitAudio(false);
          getLocalAudioTrack().then((e: any) => {
            if (localParticipant) {
              localParticipant.publishTrack(e);
            }
          });
        }
      }
      if (videoTrack) {
      } else {
        if (isFirstInitVideo && roomType !== 'audio') {
          setIsFirstInitVideo(false);
          getLocalVideoTrack().then((e: any) => {
            if (localParticipant) {
              localParticipant.publishTrack(e);
            }
          });
        }
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, audioTrack, roomType, getLocalAudioTrack]);

  return (
    <Container className={`wrap-call-page ${roomType}`}>
      {roomType !== 'chat' ? (
        <>
          <ParticipantStrip />
          <MainParticipantContainer
            style={{
              right: props.isChatRoomEnable ? theme.sidebarWidth : 0,
            }}
          >
            <MainParticipant />
          </MainParticipantContainer>
        </>
      ) : (
        ''
      )}
      {props.isChatRoomEnable || roomType === 'chat' ? <RoomChat /> : ''}
    </Container>
  );
}

import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography } from '@material-ui/core';

import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#fff',
      borderBottom: 'solid 1px #c5d6de',
      padding: '0',
    },
    logo: {
      height: '70px',
      position: 'relative',
    },
    form: {
      display: 'flex',
      alignItems: 'center',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    wrapLoading: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#ffffffd6',
    },
    loadingSpinner: {
      margin: '0',
    },
    displayName: {
      marginLeft: '2.2em',
      minWidth: '200px',
      fontWeight: 600,
    },
    extendBtn: {
      display: 'inline-block',
      marginLeft: '10px',
      padding: '5px 15px',
      background: '#a58feb',
      fontSize: '12px',
      fontWeight: 400,
      color: '#fff',
      borderRadius: '20px',
      position: 'relative',
      top: '-1px',
      cursor: 'pointer',
      '@media (max-width:800px)': {
        position: 'absolute',
        top: '-35px',
        left: 'calc(50% - 32px)',
        zIndex: 11,
      },
    },
    h3: {
      '@media (max-width:800px)': {
        color: '#222',
        margin: '0px auto',
        position: 'fixed',
        bottom: '-5px',
        left: 0,
        width: '100%',
        textAlign: 'center',
      },
    },
    spanRoomName: {
      '@media (max-width:800px)': {
        maxWidth: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        lineHeight: '13px',
        whiteSpace: 'nowrap',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const URLRoomName = params.get('room');
  const userId = params.get('name');
  const isCounselor = params.get('isCounselor');

  // const { URLRoomName } = useParams();
  const {
    user,
    getToken,
    isFetching,
    getRoomFromDB,
    deleteRoomFromDB,
    channelChat,
    extendRoomFromDB,
    isExtending,
  } = useAppState();
  const {
    isConnecting,
    connect,
    seconds,
    secondsToString,
    isShowTimeLeft,
    setShowTimeLeft,
    setSeconds,
    setTimeToString,
    onError,
    roomType,
    getMobileDetect,
    room,
  } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(userId || '');
  const [roomName, setRoomName] = useState<string>(URLRoomName || '');
  const [isInit, setInit] = useState<boolean>(true);
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const device = getMobileDetect(userAgent);

  useEffect(() => {
    if (URLRoomName && isInit) {
      setRoomName(URLRoomName);
      if (userId && !isConnecting && roomState === 'disconnected') {
        setInit(false);

        getToken(userId, URLRoomName).then(token => {
          connect(token).then(r => {
            getRoomFromDB(userId, URLRoomName).then(result => {
              const r = JSON.parse(result);
              var stillUtc = moment.utc(r.expiredTime).toDate();

              const timeEnd = getTimeRemaining(stillUtc).total / 1000;
              if (timeEnd <= 0) {
                if (channelChat) {
                  channelChat.delete().then(function(_channel: any) {
                    console.log('Deleted channel: ' + _channel.sid);
                  });
                }
                deleteRoomFromDB(userId, URLRoomName).then(result => {
                  const error = {
                    code: 9221,
                    message: 'Your room has been expired!. Please rejoin to create new.',
                  };

                  onError(error as any);
                  setTimeout(() => {
                    if (room) {
                      room.disconnect();
                    }
                  }, 3000);
                });
              }
              setSeconds(timeEnd);
              setShowTimeLeft(true);
            });
          });
        });
      }
    }
    let timer: any = null;
    if (isShowTimeLeft && seconds > 0 && !isExtending) {
      timer = setTimeout(() => {
        const date = new Date(seconds * 1000).toISOString().substr(11, 8);
        setTimeToString(date);
        setSeconds(seconds - 1);
      }, 1000);
    }

    if (userId && URLRoomName && seconds <= 0 && isShowTimeLeft) {
      if (channelChat) {
        channelChat.delete().then(function(_channel: any) {
          console.log('Deleted channel: ' + _channel.sid);
        });
      }
      deleteRoomFromDB(userId, URLRoomName).then(() => {
        const error = {
          code: 9221,
          message: 'Your room has been expired!. Please rejoin to create new.',
        };

        onError(error as any);
        room.disconnect();
      });
    }
    return () => clearTimeout(timer);
  }, [URLRoomName, connect, getToken, isConnecting, roomState, userId, seconds, secondsToString]);

  const getTimeRemaining = (endtime: any) => {
    const beginT = Date.parse(new Date() as any);
    const endT = Date.parse(endtime);

    var t = endT - beginT;
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
      total: t,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  };

  const extendRoomExpired = () => {
    if (userId && URLRoomName) {
      extendRoomFromDB(userId, URLRoomName).then((r: any) => {
        const _r = JSON.parse(r);
        var stillUtc = moment.utc(_r.expiredTime).toDate();
        const timeEnd = getTimeRemaining(stillUtc).total / 1000;
        setSeconds(timeEnd);
      });
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  return (
    <div id="header-bar">
      <AppBar className={classes.container} position="fixed">
        <Toolbar className={`tool-bar ${roomType}`}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img className={classes.logo} src="https://chat.brightshift.co/assets/custom/images/logo.png" alt="" />
            {isConnecting}
            {roomState === 'disconnected' ? (
              <div></div>
            ) : (
              // <form className={classes.form} onSubmit={handleSubmit}>
              //   {!user?.displayName ? (
              //     <TextField
              //       id="menu-name"
              //       label="Name"
              //       className={classes.textField}
              //       value={name}
              //       onChange={handleNameChange}
              //       margin="dense"
              //     />
              //   ) : (
              //     <Typography className={classes.displayName} variant="body1">
              //       {user.displayName}
              //     </Typography>
              //   )}
              //   <TextField
              //     id="menu-room"
              //     label="Room"
              //     className={classes.textField}
              //     value={roomName}
              //     onChange={handleRoomNameChange}
              //     margin="dense"
              //   />
              //   <Button
              //     style={{ color: '#fff' }}
              //     type="submit"
              //     color="primary"
              //     variant="contained"
              //     disabled={isConnecting || !name || !roomName || isFetching}
              //   >
              //     Join Room
              //   </Button>
              // </form>
              <h3 id="time-remain" className={classes.h3} style={{ margin: '0 auto' }}>
                <span className={classes.spanRoomName}>Room: {roomName} </span> - Time remaining:{' '}
                {isShowTimeLeft ? secondsToString : 'Loading...'}
                {isCounselor === '1' && seconds <= 300 ? (
                  <span className={classes.extendBtn} onClick={extendRoomExpired}>
                    Extend
                  </span>
                ) : (
                  ''
                )}
              </h3>
            )}
            <div
              style={
                roomState === 'disconnected'
                  ? {
                      display: 'flex',
                      marginLeft: 'auto',
                    }
                  : {
                      display: 'flex',
                    }
              }
            >
              {!device.isMobile() && roomType !== 'chat' ? (
                <>
                  <ToggleFullscreenButton />
                  <Menu />
                </>
              ) : (
                ''
              )}
            </div>
            {(isConnecting || isFetching) && (
              <div className={classes.wrapLoading}>
                <CircularProgress className={classes.loadingSpinner} />
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

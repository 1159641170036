import { useCallback, useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

const getMobileDetect = (userAgent: any) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));

  const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};

export default function useChatRoomToggle() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const device = getMobileDetect(userAgent);
  let isShowChatDefault = device.isMobile() ? false : true;

  const { roomType } = useVideoContext();
  if (roomType === 'chat' && device.isMobile()) {
    isShowChatDefault = true;
  }

  const [isChatRoomEnable, setIsShowChat] = useState<Boolean>(isShowChatDefault);

  useEffect(() => {}, []);

  const toggleChatRoomEnable = useCallback(() => {
    setIsShowChat(!isChatRoomEnable);
  }, [isChatRoomEnable]);

  return [isChatRoomEnable, toggleChatRoomEnable] as const;
}

import React, { ChangeEvent, FormEvent, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import Controls from './components/Controls/Controls';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import useChatRoomToggle from './hooks/useChatRoomToggle/useChatRoomToggle';
import RoomChat from './components/RoomChat/RoomChat';

import useRoomState from './hooks/useRoomState/useRoomState';
import logo from './logo.png';
import {
  CardContent,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import theme from './theme';
import { useAppState } from './state';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const Main = styled('main')({
  paddingTop: '71px',
  height: 'calc(100%)',
  position: 'relative',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapBeginRoomForm: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    logo: {
      height: '70px',
      position: 'relative',
      display: 'block',
      margin: '0 auto',
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 300,
    },
    loadingSpinner: {
      margin: '0',
    },
  })
);

export default function App() {
  const roomState = useRoomState();
  const [isChatRoomEnable, toggleChatRoomEnable] = useChatRoomToggle();
  const classes = useStyles();
  const { roomType, setRoomType, room, isConnecting } = useVideoContext();

  const { getToken, isFetching } = useAppState();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const URLRoomName = params.get('room');
  const userId = params.get('name');
  const isCounselor = params.get('isCounselor');
  const [isLoadRoom, setLoadRoom] = useState<boolean>(false);

  const [name, setName] = useState<string>(userId || '');
  const [roomName, setRoomName] = useState<string>(URLRoomName || '');

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (!URLRoomName || URLRoomName === '') {
      throw Error('No room name.');
    }
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    setLoadRoom(true);
    window.history.replaceState(
      null,
      '',
      window.encodeURI(`/room?name=${name}&room=${roomName}${isCounselor ? `&isCounselor=${isCounselor}` : ''}`)
    );
    // window.location.href = window.encodeURI(`/room?name=${name}&room=${roomName}`);
  };

  const handleChangeRoomType = (event: any) => {
    setRoomType(event.target.value);
  };

  const onDisconnect = () => {
    setLoadRoom(false);
    setTimeout(() => {
      window.location.href = window.encodeURI(
        `/room?name=${name}&room=${roomName}${isCounselor ? `&isCounselor=${isCounselor}` : ''}`
      );
    }, 1000);
  };
  if (roomState !== 'disconnected') {
    room.on('disconnected', onDisconnect);
  }
  return (
    <Container>
      {isLoadRoom ? (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <MenuBar />
          <Main>
            {roomState !== 'disconnected' ? <Room isChatRoomEnable={isChatRoomEnable} /> : ''}

            <Controls isChatRoomEnable={isChatRoomEnable} toggleChatRoomEnable={toggleChatRoomEnable} />
          </Main>
          <ReconnectingNotification />
        </div>
      ) : (
        <div id="wrap-form-contain" className={classes.wrapBeginRoomForm}>
          <Card>
            <CardContent>
              <img className={classes.logo} src={logo} alt="" />

              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  id="menu-name"
                  label="Name"
                  className={classes.textField}
                  value={name}
                  onChange={handleNameChange}
                  margin="dense"
                />
                {/* <TextField
                  id="menu-room"
                  label="Room"
                  className={classes.textField}
                  value={roomName}
                  onChange={handleRoomNameChange}
                  margin="dense"
                /> */}
                <RadioGroup
                  style={{ flexDirection: 'row', margin: '10px 0' }}
                  aria-label="gender"
                  name="roomType"
                  value={roomType}
                  onChange={handleChangeRoomType}
                >
                  <FormControlLabel value="call" control={<Radio />} label="Call Video" />
                  <FormControlLabel value="audio" control={<Radio />} label="Call Audio" />
                  {/* <FormControlLabel value="chat" control={<Radio />} label="Chat" /> */}
                </RadioGroup>
                <Button
                  style={{ color: '#fff', marginTop: 15 }}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={(!name && !roomName) || isFetching}
                >
                  Join Room
                </Button>
              </form>
              <RoomChat isLoadFromRoom={true} />
            </CardContent>
          </Card>
        </div>
      )}
    </Container>
  );
}

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import SwitchVideo from '@material-ui/icons/SwitchVideo';
import SpeakerNotesOff from '@material-ui/icons/SpeakerNotesOff';

import useChatRoomToggle from '../../../hooks/useChatRoomToggle/useChatRoomToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      color: '#fff',
      '@media (max-width:800px)': {
        width: '40px',
        height: '40px',
      },
    },
  })
);

export default function SwitchRoomButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const { roomType, setRoomType, room } = useVideoContext();

  const switchRoom = () => {
    if (roomType !== 'chat') {
      setRoomType('chat');
    } else {
      setRoomType('call');
    }
  };
  return (
    <Tooltip
      title={roomType !== 'chat' ? 'Switch to chat' : 'Switch to video'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab color="primary" className={classes.fab} onClick={switchRoom} disabled={props.disabled}>
        <SwitchVideo />
      </Fab>
    </Tooltip>
  );
}

import React from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';

interface ParticipantProps {
  participant: LocalParticipant | RemoteParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  onClick: () => void;
  isSelected: boolean;
  hide: boolean;
}

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
  hide,
}: ParticipantProps) {
  const divStyle = { display: hide ? 'none' : 'block' };
  return (
    <div style={divStyle}>
      <ParticipantInfo participant={participant} onClick={onClick} isSelected={isSelected}>
        <ParticipantTracks
          participant={participant}
          disableAudio={disableAudio}
          enableScreenShare={enableScreenShare}
        />
      </ParticipantInfo>
    </div>
  );
}

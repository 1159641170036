import React, { createContext, ReactNode, useState } from 'react';
import { ConnectOptions, Room, TwilioError, LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import { Callback, ErrorCallback } from '../../types';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRoom from './useRoom/useRoom';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  getLocalVideoTrack: () => Promise<LocalVideoTrack>;
  getLocalAudioTrack: () => Promise<LocalAudioTrack>;
  seconds: any;
  secondsToString: any;
  setSeconds: any;
  roomType: any;
  setRoomType: any;
  isShowTimeLeft: any;
  setShowTimeLeft: any;
  setTimeToString: any;
  getMobileDetect: any;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  onDisconnect?: Callback;
  children: ReactNode;
}

const getMobileDetect = (userAgent: any, navigator: any) => {
  let isIpad = false;
  if (navigator) {
    isIpad = /iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  }
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIosMobile = () => Boolean(userAgent.match(/iPhone|iPod/i));
  const isIosIpad = () => isIpad;
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isMobile = () => Boolean(isAndroid() || isIosMobile() || isIosIpad() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIosMobile,
    isIosIpad,
    isSSR,
  };
};

export function VideoProvider({ options, children, onError = () => {}, onDisconnect = () => {} }: VideoProviderProps) {
  const onErrorCallback = (error: TwilioError) => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };
  const [roomType, setRoomType] = useState<string>('call');
  const { localTracks, getLocalVideoTrack, getLocalAudioTrack } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);
  const [seconds, setSeconds] = useState(3600);
  const [secondsToString, setTimeToString] = useState<string>('');
  const [isShowTimeLeft, setShowTimeLeft] = useState<boolean>(false);
  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);
  // if (room && room.localParticipant && room.localParticipant.state === 'connected' && isShowTimeLeft) {

  // }
  return (
    <VideoContext.Provider
      value={{
        isShowTimeLeft,
        setShowTimeLeft,
        roomType,
        setRoomType,
        setSeconds,
        seconds,
        secondsToString,
        setTimeToString,
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        getMobileDetect,
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  );
}
